import usePageContext from '@/utils/context/page';
import React, { useMemo } from 'react';
import { Group, createStyles, useMantineTheme, clsx } from '@mantine/core';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import NoozBadge from '@/components/Nooz/NoozBadge/NoozBadge';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { ProductTemplateProps } from '@/interface.custom';
import { PRODUCT_LAYOUT_MEDIA_QUERY } from '@/templates/Product';
import { marginMobile } from '../Product/ProductInformation/ProductInformation';
import useProductContext from '@/utils/context/product';
import { useHighlightStyles } from '@/utils/style/useHighlightStyles';
import { useMediaQuery } from '@mantine/hooks';

const useStyles = createStyles(
  (theme, { hasBadgeToShow }: Record<string, boolean>) => ({
    topWrapper: {
      padding: `32px 0`,
      margin: '0 60px',
      zIndex: 1,
      '& > .usps > *:not(:last-of-type)': {
        marginRight: 8,
      },
      [theme.fn.smallerThan(PRODUCT_LAYOUT_MEDIA_QUERY)]: {
        margin: 0,
        padding: marginMobile,
        paddingTop: 20,
        '& > .usps': {
          width: '100%',
          justifyContent: 'space-between',
          // ...(hasBadgeToShow ? {} : { display: 'none' }),
        },
      },
    },
    whiteBadge: {
      backgroundColor: theme.white,
    },
  }),
);

const ProductDisplayerTop = () => {
  const { variant } = useProductContext();

  const highlightStyles = useHighlightStyles();
  const { breadcrumbs, product } =
    usePageContext() as unknown as ProductTemplateProps;
  const theme = useMantineTheme();
  const { classes } = useStyles({
    // @ts-ignore
    hasBadgeToShow:
      // @ts-ignore
      variant?.fields?.hasPromotion &&
      // @ts-ignore
      variant?.fields?.promoBadge,
  });
  const usps = product.fields.collection?.fields?.usps?.slice(0, 1) || [];
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);

  const secondaryBadge = useMemo(() => {
    if (product?.fields.stateBadge) {
      const currBadge = product.fields.stateBadge;
      // @ts-ignore
      const type = currBadge.fields.color.fields.gradientObject
        ? 'gradient'
        : // @ts-ignore
        currBadge.fields.type === 'contained'
        ? 'filled'
        : 'outline';

      return (
        <NoozBadge
          badgeProps={{
            size: 'xl',
            color:
              currBadge?.fields.label?.fields?.color?.fields.hex ||
              theme.colors.text[0],
            className: classes.whiteBadge,
            sx: {
              background:
                type === 'outline'
                  ? undefined
                  : currBadge.fields.color?.fields.hex || 'white',
            },
            gradient: currBadge.fields.color?.fields.gradientObject as any,
            variant: type,
          }}
          textProps={{
            size: isDesktop ? 12 : 10,
            weight: 500,
            color: 'dimmed',
            sx: {
              color:
                type === 'outline'
                  ? currBadge.fields.color?.fields.hex || theme.colors.gray[6]
                  : theme.colors.white[0],
            },
          }}
          icon={
            <NoozIcon
              iconKey={currBadge.fields.icon?.fields.iconKey || 'Discount'}
              size={isDesktop ? 17 : 12}
              color={
                type === 'outline'
                  ? currBadge.fields.color?.fields.hex || theme.colors.gray[6]
                  : theme.colors.white[0]
              }
            />
          }
          text={currBadge.fields?.label?.fields.text || ''}
        />
      );
    } else {
      //@ts-ignore
      return usps?.map(({ id, fields: { badge } }) => {
        return badge ? (
          <NoozBadge
            key={id}
            badgeProps={{
              size: 'xl',
              color:
                badge?.fields.label?.fields.color?.fields.hex ||
                theme.colors.text[0],
              className: classes.whiteBadge,
            }}
            textProps={{
              color: 'dimmed',
              size: 'sm',
            }}
            iconPosition={'right'}
            icon={
              badge?.fields.icon ? (
                <NoozIcon
                  iconKey={badge?.fields.icon.fields.iconKey}
                  size={18}
                  color={
                    badge.fields.color?.fields?.hex || theme.colors.title[0]
                  }
                />
              ) : undefined
            }
            text={badge?.fields.label?.fields.text || ''}
          />
        ) : null;
      });
    }
  }, [product, variant]);

  const promoBadge = useMemo(() => {
    if (
      // @ts-ignore
      variant?.fields?.hasPromotion &&
      // @ts-ignore
      variant?.fields?.promoBadge
    ) {
      // @ts-ignore
      const currBadge = variant.fields.promoBadge;
      return (
        <NoozBadge
          badgeProps={{
            size: 'xl',
            color: theme.colors.white[0],
            variant: 'filled',
            sx: {
              backgroundColor: currBadge.fields.color?.fields.hex,
            },
          }}
          icon={
            <NoozIcon
              iconKey={'Discount'}
              size={isDesktop ? 17 : 12}
              color={theme.colors.white[0]}
            />
          }
          textProps={{
            size: 'sm',
            color: theme.colors.white[0],
            pl: 8,
          }}
          text={currBadge.fields?.label?.fields.text || ''}
        />
      );
    }

    return null;
  }, [variant]);

  const mediaQuery = theme.fn.smallerThan('md');
  const isMobile = useMediaQuery(mediaQuery.split('@media ')[1]);
  return (
    <Group
      position='apart'
      className={classes.topWrapper}>
      {!isMobile ? <Breadcrumbs list={breadcrumbs} /> : null}
      {isMobile ? (
        <Group className={'usps'}>
          {/* @ts-ignore */}
          {promoBadge ? promoBadge : null}
          {secondaryBadge ? secondaryBadge : null}
          {variant?.fields?.new ? (
            <NoozBadge
              badgeProps={{
                size: 'xl',
                variant: 'white',
                className: clsx(
                  highlightStyles.classes.outlineButton,
                  'active',
                ),
              }}
              textProps={{
                size: 'sm',
                className: clsx(highlightStyles.classes.text, 'active'),
              }}
              text={'New'}
            />
          ) : null}
          {variant?.fields?.promotion ? (
            <NoozBadge
              badgeProps={{
                size: 'xl',
                variant: 'white',
                className: clsx(
                  highlightStyles.classes.outlineButton,
                  'active',
                ),
              }}
              textProps={{
                size: 'sm',
                className: clsx(highlightStyles.classes.text, 'active'),
              }}
              text={variant?.fields?.promotion.fields?.label?.fields.text || ''}
            />
          ) : null}
        </Group>
      ) : (
        <Group className={'usps'}>
          {/* @ts-ignore */}
          {promoBadge ? promoBadge : null}
          {secondaryBadge ? secondaryBadge : null}
          {variant?.fields?.new ? (
            <NoozBadge
              badgeProps={{
                size: 'xl',
                variant: 'gradient',
                gradient: { from: '#1e4ae3', to: '#009fe3', deg: 90 },
                // variant: 'white',
                // className: clsx(
                //   highlightStyles.classes.outlineButton,
                //   'active',
                // ),
              }}
              textProps={{
                size: 'sm',
                className: clsx(highlightStyles.classes.text, 'active'),
              }}
              text={'New'}
            />
          ) : null}
          {variant?.fields?.promotion ? (
            <NoozBadge
              badgeProps={{
                size: 'xl',
                variant: 'white',
                className: clsx(
                  highlightStyles.classes.outlineButton,
                  'active',
                ),
              }}
              textProps={{
                size: 'sm',
                className: clsx(highlightStyles.classes.text, 'active'),
              }}
              text={variant?.fields?.promotion.fields?.label?.fields.text || ''}
            />
          ) : null}
        </Group>
      )}
    </Group>
  );
};

export default ProductDisplayerTop;
