import usePageContext from '@/utils/context/page';
import React, { useCallback, useEffect, useMemo } from 'react';
import { IPage } from '@/@types/generated/contentful';
import useProductContext from '@/utils/context/product';
import {
  Box,
  Checkbox,
  clsx,
  createStyles,
  Divider,
  Flex,
  Group,
  Indicator,
} from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import Currency from '@/helpers/Currency';
import { UiColorPickerProps } from '@/interface.custom';
import { useProductInfoStyles } from '@/components/Product/ProductInformation/ProductInformation';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useModalContext } from '@/utils/context/modal';
import { Body } from '@/components/Body/body';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';

// todo add deg-bli
const BLUELIGHT_CAT_ID = [
  '2pM1w5oM1J69d3trRefhs7', // Product - Glasses - Bluelight
  '6CMUOm1GonB8AAB1BlEG11', // Product - Glasses  - Reading - Bluelight
  '6o8ENpXZ6nKb578dXY4k7B', // Product - Glasses - Kids - Bluelight
];

const useChipStyles = createStyles((theme) => ({
  chip: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    cursor: 'pointer',
    border: `1px solid ${theme.colors.text[0]}`,
    padding: '5px 15px',
  },
  active: {
    color: theme.white,
    backgroundColor: theme.colors.text[0],
  },
  disabled: {
    cursor: 'not-allowed',
  },
}));

const ProductLensesPicker = ({ styles }: UiColorPickerProps) => {
  const { product, page, productCategory, locale } = usePageContext();
  const staticContent = useMemo(
    // @ts-ignore
    () => (page as IPage).fields.pageType.staticItems,
    [page],
  );
  const router = useRouter();
  const sp = useSearchParams();
  const { variant } = useProductContext();
  const { lenses } = product as unknown as { lenses: LensesInformation[] };
  const productInfoStyles = useProductInfoStyles();

  const { classes } = useChipStyles();
  const handleOptionClick = (productLink: string) => {
    const path = `/${locale}/${productLink}${
      window.location.search
        ? `?${window.location.search.replace(/^\?/, '')}`
        : ''
    }`;
    router.replace(path, path, { scroll: false });
  };

  const { setModal } = useModalContext();

  const openHelperModal = useCallback(() => {
    if (productCategory?.fields.guideRichBody)
      setModal({
        opened: true,
        size: 'xl',
        radius: 'lg',
        centered: true,
        body: (
          <Box>
            <Body
              richBody={productCategory.fields.guideRichBody.fields.richBody}
            />
          </Box>
        ),
      });
  }, [productCategory]);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const color = new URLSearchParams(window.location.search).get('color');
      for (const lv of lenses) {
        router.prefetch(
          `/${locale}/${lv.link}${color ? `?color=${color}` : ''}`,
        );
      }
    }
  }, []);

  return !lenses || lenses?.length <= 1 ? null : (
    <Box className={productInfoStyles.classes.infoContentWrapper}>
      <Divider className={styles.smallDivider} />
      <div className={styles.itemWrapper}>
        <NoozText
          color={'title'}
          fw={900}
          size={14}
          mb={16}>
          {staticContent.lensesOptions}
        </NoozText>
        <Group
          sx={(theme) => ({
            // [theme.fn.largerThan(theme.breakpoints.md)]: {
            //   flexWrap: 'nowrap',
            //   overflow: 'auto',
            //   '&::-webkit-scrollbar': {
            //     display: 'none',
            //   },
            // },
          })}>
          {lenses
            /* .filter(({ catId }) => !BLUELIGHT_CAT_ID.includes(catId)) */
            .map((lensesVariant, pos) => {
              const isDisabled = !(lensesVariant.colors || []).includes(
                variant.fields.color?.fields.title || '',
              );

              return (
                <Indicator
                  key={lensesVariant.id}
                  sx={{
                    '& .mantine-Indicator-indicator': {
                      zIndex: 1,
                      background: lensesVariant.color?.fields.hex,
                      height: 'fit-content',
                      width: 'fit-content',
                      padding: '0px 10px',
                    },
                  }}
                  disabled={!lensesVariant.isNew}
                  size={16}
                  label={'New'}>
                  <NoozButton
                    disabled={isDisabled}
                    variant={
                      lensesVariant.catId === (productCategory as any).id
                        ? 'filled'
                        : 'outline'
                    }
                    color={'black'}
                    className={clsx(classes.chip, {
                      [classes.active]:
                        lensesVariant.catId === (productCategory as any).id,
                      [classes.disabled]: isDisabled,
                    })}
                    onClick={() => {
                      if (isDisabled) return;
                      handleOptionClick(lensesVariant.link as string);
                    }}>
                    <NoozText
                      weight={400}
                      size={12}>
                      {`${lensesVariant.title}`}
                      <b>{`${
                        lensesVariant.priceDelta <= 0
                          ? ''
                          : ` +${lensesVariant.priceDelta
                              .toFixed(2)
                              .replace('.00', '')}${Currency.toSymbol(
                              variant.shopifyInfo?.price?.currencyCode,
                            )}`
                      }`}</b>
                    </NoozText>
                  </NoozButton>
                </Indicator>
              );
            })}
        </Group>
        {/*{lenses.filter(({ catId }) => BLUELIGHT_CAT_ID.includes(catId)).length >*/}
        {/*0 ? (*/}
        {/*  <Checkbox*/}
        {/*    mt={16}*/}
        {/*    label='Bluelight'*/}
        {/*    disabled={false /* todo *!/*/}
        {/*    defaultValue={variant.fields.title.includes('-BLI-').toString()}*/}
        {/*    onChange={(event) => {*/}
        {/*      const toBluelight = event.currentTarget.checked,*/}
        {/*        // todo fetch bluelight or parent category*/}
        {/*        cat = lenses.find((l) => BLUELIGHT_CAT_ID.includes(l.catId));*/}
        {/*      if (toBluelight && cat && cat?.link) {*/}
        {/*        const path = `/${locale}/${cat.link}${*/}
        {/*          window.location.search*/}
        {/*            ? `?${window.location.search.replace(/^\?/, '')}`*/}
        {/*            : ''*/}
        {/*        }`;*/}
        {/*        router.replace(path, path, { scroll: false });*/}
        {/*      } else if (!toBluelight && cat) {*/}
        {/*        // todo*/}
        {/*      }*/}
        {/*    }}*/}
        {/*  />*/}
        {/*) : null}*/}

        {productCategory?.fields.guideLabels &&
        productCategory?.fields.guideLabels?.length > 0 &&
        productCategory?.fields.guideRichBody ? (
          <Flex
            gap={4}
            sx={{ cursor: 'pointer' }}
            onClick={openHelperModal}
            mt={12}>
            {productCategory?.fields.guideLabels.map((label) => (
              <NoozText
                key={(label as any).id}
                color={'title'}
                size={10}
                transform={'uppercase'}
                weight={
                  label.fields.weight &&
                  ['bold', 'black'].includes(label.fields.weight)
                    ? 900
                    : 400
                }
                underline={label.fields.decoration === 'underline'}
                component={'span'}>
                {label.fields.text}
              </NoozText>
            ))}
          </Flex>
        ) : null}
      </div>
    </Box>
  );
};

export default ProductLensesPicker;
