import usePageContext from '@/utils/context/page';
import { Box, Container, Flex } from '@mantine/core';
import React, { useEffect, useMemo } from 'react';
import AddToCartButton from '@/components/AddToCartButton/AddToCartButton';
import { subscribe, unsubscribe } from '@/helpers/events';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import useProductContext from '@/utils/context/product';
import Currency from '@/helpers/Currency';
import zIndexes from '@/utils/style/zIndex';
import { ProductTemplateProps } from '@/interface.custom';

const ProductInformationSticky = ({ price }: { price?: any }) => {
  const [isActive, setIsActive] = React.useState(false);
  const { product } = usePageContext() as unknown as ProductTemplateProps;
  const { variant } = useProductContext();

  useEffect(() => {
    const fct: EventListenerOrEventListenerObject = (evt) => {
      // @ts-ignore
      if (typeof evt.detail.outOfWindow === 'boolean') {
        // @ts-ignore
        setIsActive(evt.detail.outOfWindow);
      }
    };

    subscribe('header', fct);

    return () => {
      unsubscribe('header', fct);
    };
  }, []);

  const optDiopter = useMemo(() => {
    const diopter = variant.shopifyInfo.selectedOptions.find(
      (o: Record<string, any>) => o.name === 'Diopter',
    );
    if (diopter && diopter.value !== '0')
      return (
        <NoozText
          component={'span'}
          size={24}
          fw={500}
          color={'text'}>
          {` - ${diopter.value}`}
        </NoozText>
      );

    return null;
  }, [variant]);

  const optColor = useMemo(() => {
    if (variant.fields?.color?.fields.text)
      return (
        <NoozText
          component={'span'}
          size={24}
          fw={500}
          color={'text'}>
          {` - ${variant.fields.color.fields.text}`}
        </NoozText>
      );

    return null;
  }, [variant]);

  return (
    <>
      {isActive && (
        <Box
          py='lg'
          bg='white'
          w='100vw'
          sx={{ position: 'fixed', bottom: 0, zIndex: zIndexes.header }}>
          <Container size={'xl'}>
            <Flex
              gap={40}
              align={'center'}
              justify={'space-between'}>
              <Flex align={'center'}>
                <NoozText
                  size={24}
                  fw={500}
                  mr={12}
                  color={'title'}>
                  {`${product.fields.label?.fields.text || ''}`}

                  {optDiopter}
                  {optColor}
                </NoozText>
                <Box
                  h={26}
                  w={26}
                  sx={{
                    borderRadius: 20,
                    background: variant.fields.color?.fields.hex,
                  }}
                />
              </Flex>
              <Flex
                gap={20}
                sx={{ flex: 1 }}
                align={'center'}>
                {price ? (
                  <NoozText
                    color={'title'}
                    size={26}
                    fw={700}>
                    {Currency.toFormattedPrice(price.amount)}
                    <NoozText
                      component={'span'}
                      sx={{
                        color:
                          product.fields.category?.fields.color?.fields.hex,
                      }}
                      ml={4}
                      size={24}
                      fw={600}>
                      {Currency.toSymbol(price.currencyCode)}
                    </NoozText>
                  </NoozText>
                ) : null}
                <Box miw={275}>
                  <AddToCartButton hasPrice={!!price} />
                </Box>
              </Flex>
            </Flex>
          </Container>
        </Box>
      )}
    </>
  );
};

export default ProductInformationSticky;
