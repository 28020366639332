import usePageContext from '@/utils/context/page';
import { Box, Container, Flex, useMantineTheme } from '@mantine/core';
import React, { useEffect } from 'react';
import { subscribe, unsubscribe } from '@/helpers/events';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import Currency from '@/helpers/Currency';
import AddToCartPackButton from '@/components/pages/ProductPack/AddToCartPackButton';
import { ProductTemplateProps } from '@/interface.custom';

const PackProductInfoSticky = ({
  price,
  compareAtPrice,
}: {
  price: any;
  compareAtPrice: any;
}) => {
  const [isActive, setIsActive] = React.useState(false);
  const { product, shopifyProduct } =
    usePageContext() as unknown as ProductTemplateProps;
  const theme = useMantineTheme();

  useEffect(() => {
    const fct: EventListenerOrEventListenerObject = (evt) => {
      // @ts-ignore
      if (typeof evt.detail.outOfWindow === 'boolean') {
        // @ts-ignore
        setIsActive(evt.detail.outOfWindow);
      }
    };

    subscribe('header', fct);

    return () => {
      unsubscribe('header', fct);
    };
  }, []);
  return (
    <>
      {isActive && (
        <Box
          py='lg'
          bg='white'
          w='100vw'
          sx={{ position: 'fixed', bottom: 0, zIndex: 1000 }}>
          <Container size={'xl'}>
            <Flex align={'center'}>
              <Flex
                sx={{ flex: 1 }}
                align={'center'}>
                <NoozText
                  size={24}
                  fw={500}
                  mr={12}
                  color={'title'}>
                  {`${product.fields.label?.fields.text || ''}`}
                </NoozText>
              </Flex>
              <Flex align={'center'}>
                {price ? (
                  <NoozText
                    color={
                      shopifyProduct?.variants[0].compareAtPrice
                        ? theme.colors.red[6]
                        : 'title'}
                    size={26}
                    mr={20}
                    fw={700}>
                    {price.amount}
                    <NoozText
                      component={'span'}
                      color={
                        shopifyProduct?.variants[0].compareAtPrice
                          ? theme.colors.red[6]
                          : 'title'
                      }
                      ml={4}
                      size={26}
                      fw={600}>
                      {Currency.toSymbol(price.currencyCode)}
                    </NoozText>
                  </NoozText>
                ) : null}
                {compareAtPrice ? (
                  <NoozText
                    color={'text'}
                    size={26}
                    mr={20}
                    td='line-through'
                    fw={700}
                    w={'-webkit-fill-available'}>
                    {`${compareAtPrice?.amount} ${Currency.toSymbol(compareAtPrice?.currencyCode)}`}
                  </NoozText>
                ) : null}
                <AddToCartPackButton hasPrice={!!price} />
              </Flex>
            </Flex>
          </Container>
        </Box>
      )}
    </>
  );
};

export default PackProductInfoSticky;
