import usePageContext from '@/utils/context/page';
import React from 'react';
import { Container, createStyles, Group, Stack, Title } from '@mantine/core';
import useStaticContent from '@/utils/hook/useStaticContent';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import Link from '@/components/Link/Link';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import dynamic from 'next/dynamic';
const ShopstoryBlock = dynamic(() =>
  import('@/components/Shopstory/Shopstory').then((mod) => mod.ShopstoryBlock),
);

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  inner: {
    position: 'relative',
  },

  image: {
    ...theme.fn.cover(),
    opacity: 0.75,
  },

  content: {
    position: 'relative',
    zIndex: 1,

    [theme.fn.smallerThan('sm')]: {
      paddingTop: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 540,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },
}));

const Other = () => {
  const { shopstory, localizedSlugs } = usePageContext();
  const { classes } = useStyles();
  const staticContent = useStaticContent();

  if (
    (localizedSlugs?.en === '404' || localizedSlugs?.en === '500') &&
    !shopstory
  ) {
    return (
      <Container className={classes.root}>
        <div className={classes.inner}>
          <Stack>
            <Title
              order={1}
              size={60}
              align='center'>
              {localizedSlugs?.en}
            </Title>
            <NoozText
              align='center'
              className={classes.title}>
              {staticContent.errorPageLabel}
            </NoozText>
            <NoozText
              color='dimmed'
              size='lg'
              align='center'
              className={classes.description}>
              {staticContent.errorPageSubLabel}
            </NoozText>
            <Link href={`/`}>
              <Group position='center'>
                <NoozButton>{staticContent.backToHomeLabel}</NoozButton>
              </Group>
            </Link>
          </Stack>
        </div>
      </Container>
    );
  }
  if (shopstory) return <ShopstoryBlock {...shopstory} />;
  return null;
};

export default Other;
