import {
  Box,
  Container,
  Divider,
  Flex,
  Indicator,
  MantineNumberSize,
  MediaQuery,
  Title,
  clsx,
  createStyles,
} from '@mantine/core';
import React, { useEffect, useMemo, useState } from 'react';
import useCartItems from '@/utils/hook/useCartItems';
import LineItem from '@/components/pages/Cart/LineItem';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import useStaticContent from '@/utils/hook/useStaticContent';
import Checkout, { StickyCheckout } from '@/components/pages/Cart/Checkout';
import i18n from '@/helpers/i18n';
import CartFreeShipping from '@/components/Cart/CartFreeShipping/CartFreeShipping';
import Datalayer from '@/helpers/Datalayer';
import useCommerceContext from '@/utils/context/commerce';
import {
  MOBILE_CONTAINER_PADDING,
  useContainerStyles,
} from '@/utils/style/useContainer';
import { useFreeShipping } from '@/utils/hook/cart/useFreeShipping';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import usePageContext from '@/utils/context/page';
import NoozNextApi, { NOOZ_NEXT_REGISTRY } from '@/utils/services/NoozNextApi';
import useCartActions from '@/utils/hook/cart/useCartActions';
import { ICartLine } from '@/interface.custom';

export const CART_BREAKPOINT = 'md' as MantineNumberSize;

const useStyles = createStyles((theme) => ({
  container: {
    marginTop: 80,
    marginBottom: 120,
    [theme.fn.smallerThan(CART_BREAKPOINT)]: {
      marginTop: 20,
      padding: 0,
    },
  },
  label: {
    fontSize: 48,
    fontWeight: 600,
    marginRight: 16,
    [theme.fn.smallerThan(CART_BREAKPOINT)]: {
      fontSize: 24,
      margin: 0,
      fontWeight: 700,
    },
  },
}));

const CartUI = () => {
  const { removeFromCart } = useCartActions();
  const page = usePageContext();
  const [loading, setLoading] = useState(true);
  const freeShipping = useFreeShipping();
  const { classes } = useStyles();
  const { cart } = useCommerceContext();
  const staticContent = useStaticContent();
  const lineItems = useCartItems();
  const [initialCart, setInitialCart] = useState(lineItems);
  const cartLines = useMemo(() => {
    return [...initialCart.filter((li) => li.soldOut), ...lineItems];
  }, [initialCart, lineItems]);

  const nbItems = lineItems.reduce(
    (qty: number, item) => qty + item.quantity,
    0,
  );

  const headerHeight = useHeaderHeight();
  const containerStyles = useContainerStyles();

  useEffect(() => {
    if (cart) {
      Datalayer.viewCart(cart);
      const variantIds = [
        // @ts-ignore
        ...new Set(
          cart.lineItems
            .filter(
              (li) =>
                li.customAttributes.find((ca) => ca.key === '__itemType')
                  ?.value !== 'pack',
            )
            .map((li) => li.variant?.id),
        ),
      ];
      console.log('=>', cart.lineItems);
      NoozNextApi.instance({
        ...NOOZ_NEXT_REGISTRY.SHOPIFY.VARIANTS.GET_STOCK,
        data: { ids: variantIds, locale: page.locale },
      })
        .then(async ({ data: stocks }) => {
          let toRemove: ICartLine[] = [];
          initialCart.forEach((li) => {
            li.lineItems.forEach((i) => {
              const type = i.customAttributes.find(
                (ca) => ca.key === '__itemType',
              )?.value as string;

              if (type !== 'pack' && i.variant) {
                const stock = i.variant?.id ? stocks[i.variant.id] || 0 : 0;
                if (stock - i.quantity < 0 && i.title !== 'Digital Gift Card') {
                  // @ts-ignore
                  li.soldOut = true;
                } else {
                  stocks[i.variant.id] -= i.quantity;
                }
              }
            });
            // @ts-ignore
            if (li.soldOut) {
              toRemove = toRemove.concat(li.lineItems);
            }
          });
          setInitialCart(initialCart);

          if (toRemove?.length > 0) await removeFromCart(toRemove);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <>
      <MediaQuery
        largerThan={CART_BREAKPOINT}
        styles={{ display: 'none' }}>
        <span>
          <StickyCheckout loading={loading} />
        </span>
      </MediaQuery>
      <Container
        className={clsx(containerStyles.classes.container, classes.container)}
        sx={() => ({
          display: 'flex',
          flexDirection: 'column',
        })}>
        <Flex
          sx={(theme) => ({
            height: 'fit-content',
            gap: '3rem',
            marginLeft: 30,
            [theme.fn.smallerThan(CART_BREAKPOINT)]: {
              marginLeft: 0,
            },
          })}>
          <Flex
            sx={{ gap: '1.75rem', flex: 1 }}
            direction={'column'}>
            <Flex
              direction={'column'}
              sx={(theme) => ({
                [theme.fn.smallerThan(CART_BREAKPOINT)]: {
                  padding: `0px ${MOBILE_CONTAINER_PADDING}px`,
                },
              })}>
              <Flex
                sx={(theme) => ({
                  [theme.fn.smallerThan(CART_BREAKPOINT)]: {
                    justifyContent: 'start',
                  },
                })}>
                <Title
                  className={classes.label}
                  order={1}>
                  {staticContent.myCartLabel}
                </Title>
                <Indicator
                  ml={10}
                  color={'main'}
                  position='middle-center'
                  inline
                  label={nbItems}
                  size={30}
                  styles={{ common: { fontSize: 16 } }}>
                  <Box w={30} />
                </Indicator>
              </Flex>

              <MediaQuery
                largerThan={CART_BREAKPOINT}
                styles={{ display: 'none' }}>
                <Flex direction={'column'}>
                  {freeShipping ? (
                    <>
                      {freeShipping.missingAmount > 0 ? (
                        <>
                          <Divider my={20} />
                          <Box>
                            <NoozText
                              size={16}
                              weight={500}
                              mb={10}>
                              {
                                i18n(staticContent, 'freeShippingLabel').split(
                                  '{{amount}}',
                                )[0]
                              }

                              <NoozText
                                component={'span'}
                                size={14}
                                weight={700}>
                                {` ${freeShipping.missingAmount.toFixed(2)} ${
                                  freeShipping.currency
                                } `}
                              </NoozText>
                              {
                                i18n(staticContent, 'freeShippingLabel').split(
                                  '{{amount}}',
                                )[1]
                              }
                            </NoozText>
                            <CartFreeShipping
                              size={'lg'}
                              showLabel={false}
                            />
                          </Box>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </Flex>
              </MediaQuery>
            </Flex>

            <Box
              sx={(theme) => ({
                [theme.fn.smallerThan(CART_BREAKPOINT)]: {
                  padding: `0px ${MOBILE_CONTAINER_PADDING}px`,
                },
              })}>
              {cartLines.map((li, key: number) => (
                <LineItem
                  key={li.__itemId}
                  hasDivider={key !== 0}
                  loading={loading}
                  {...li}
                />
              ))}
            </Box>

            {cartLines.length === 0 && (
              <Flex
                w='100%'
                align={'center'}
                justify={'start'}
                sx={(theme) => ({
                  [theme.fn.smallerThan(CART_BREAKPOINT)]: {
                    padding: `0px ${MOBILE_CONTAINER_PADDING}px`,
                  },
                })}>
                <NoozText
                  size={40}
                  color='gray'
                  weight='500'>
                  {i18n(staticContent, 'emptyCart')}
                </NoozText>
              </Flex>
            )}

            {/*<Divider my={20} />*/}
            {/*<CartUpsells />*/}

            <MediaQuery
              largerThan={CART_BREAKPOINT}
              styles={{ display: 'none' }}>
              <Flex direction={'column'}>
                <Checkout loading={loading} />
                {/*<Review />*/}
              </Flex>
            </MediaQuery>

            {/*{lineItems.length > 0 && <CartRecommendations />}*/}
          </Flex>
          <Flex
            direction={'column'}
            sx={(theme) => ({
              position: 'sticky',
              top: headerHeight.wrapper + 20,
              width: 450,
              minHeight: 200,
              height: 'fit-content',
              [theme.fn.smallerThan(theme.breakpoints.lg)]: {
                width: 300,
              },
              [theme.fn.smallerThan(CART_BREAKPOINT)]: {
                display: 'none',
              },
            })}>
            <Checkout loading={loading} />
            {/*<Review />*/}
          </Flex>
        </Flex>
      </Container>
    </>
  );
};

const Cart = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted ? <CartUI /> : null;
  // return null;
};

export default Cart;
