import { SearchInput, useInput } from '@/components/Search/Search';
import i18n from '@/helpers/i18n';
import useStaticContent from '@/utils/hook/useStaticContent';
import { useContainerStyles } from '@/utils/style/useContainer';
import {
  Box,
  Container,
  Title,
  createStyles,
  Loader,
  Flex,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import SearchHits from '@/components/Search/SearchHits/SearchHits';
import { useGetRecommendations } from '@/components/Search/SearchView/SearchView';

const useStyles = createStyles((theme) => ({
  container: {
    maxWidth: 'unset',
    padding: '0 64px 32px',
  },
  gridCol: {
    marginBottom: 30,
  },
  loadMoreWrapper: {
    gridColumn: '1 / span 4',
    display: 'flex',
    justifyContent: 'center',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      gridColumn: '1 / span 3',
    },
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      gridColumn: '1 / span 2',
    },
  },
}));

const Search: React.FC = () => {
  const containerStyles = useContainerStyles();
  const staticContent = useStaticContent();
  const input = useInput();
  const { loading: recoLoading, reco } = useGetRecommendations(input, true);
  const [pageLoading, setPageLoading] = useState(true);
  useEffect(() => {
    if (pageLoading && !recoLoading) {
      setPageLoading(false);
    }
  }, [recoLoading, pageLoading]);
  const { classes } = useStyles();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      input.onChange(
        new URLSearchParams(window.location.search).get('query') || '',
      );
    }
  }, []);

  useEffect(() => {
    if (recoLoading && typeof window !== 'undefined') {
      let url = new URL(window.location.href.split('?')[0]);
      url.searchParams.delete('query');
      if (input.query) url.searchParams.append('query', input.query);
      history.replaceState(history.state, '', url.href);
    }
  }, [recoLoading, input.query]);

  return (
    <>
      <Container
        className={containerStyles.classes.container}
        py={50}>
        <Title
          mb={40}
          size={48}
          order={1}>
          {i18n(staticContent, 'label')}
        </Title>
        <Box>
          <SearchInput
            {...input}
            loading={recoLoading || pageLoading}
          />
        </Box>
      </Container>
      {recoLoading || pageLoading ? (
        <Flex
          mb={32}
          justify={'center'}>
          <Loader />
        </Flex>
      ) : (
        <Container className={classes.container}>
          <SearchHits
            hits={reco.items}
            query={input.query}
          />
        </Container>
      )}
    </>
  );
};

export default Search;
