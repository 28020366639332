import React, { useMemo } from 'react';
import useProductContext, { IVariant } from '@/utils/context/product';
import {
  clsx,
  createStyles,
  Group,
  Divider,
  Box,
  Tooltip,
  Flex,
} from '@mantine/core';
import { useRouter } from 'next/router';
import usePageContext from '@/utils/context/page';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import {
  ProductDiopterPickerProps,
  UIDiopterPickerProps,
} from '@/interface.custom';
import useStaticContent from '@/utils/hook/useStaticContent';
import useLocalizedSlug from '@/utils/hook/useLocalizedSlug';
import { useProductInfoStyles } from '../ProductInformation/ProductInformation';
import i18n from '@/helpers/i18n';
import { LAST_VIEWED_EVENT } from '@/helpers/lastViewedItems';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import useShopifyProductContext from '@/utils/context/shopifyProduct';
import ProductEyeTest from '@/components/ProductEyeTest/ProductEyeTest';

export const useChipStyles = createStyles((theme) => ({
  chip: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    width: 52,
    height: 26,
    borderRadius: 13,
    cursor: 'pointer',
    border: `1px solid ${theme.colors.text[0]}`,
  },
  active: {
    color: theme.white,
    backgroundColor: theme.colors.text[0],
  },
  disabled: {
    cursor: 'not-allowed',
    backgroundImage: 'url("/assets/stroke.svg")',
  },
}));

export const UIDiopterPicker = ({
  values,
  active,
  handleDiopter,
  styles,
}: UIDiopterPickerProps & {
  values: string[];
  active: IVariant | null;
  handleDiopter: (key: string) => void;
}) => {
  const router = useRouter();
  const { product } = usePageContext();
  const staticContent = useStaticContent();
  const localizedEyeTest = useLocalizedSlug(staticContent['Help - eye-test']);
  const productUpdated = useShopifyProductContext() as any;
  const { classes } = useChipStyles();
  const productInfoStyles = useProductInfoStyles();

  return (
    <Box className={productInfoStyles.classes.infoContentWrapper}>
      <Divider className={styles.smallDivider} />
      <div className={styles.itemWrapper}>
        <NoozText
          color={'title'}
          fw={900}
          size={14}
          mb={16}>
          {staticContent.diopter}

          {product?.fields.collection?.fields.diopterTooltip ? (
            <Tooltip
              withArrow
              multiline
              arrowSize={6}
              width={300}
              events={{ hover: true, focus: true, touch: true }}
              sx={{ fontWeight: 'normal', fontSize: 12, borderRadius: 10 }}
              label={
                product?.fields.collection?.fields.diopterTooltip.fields.text
              }>
              <Flex
                gap={4}
                align='center'>
                <NoozText
                  span
                  size={12}
                  fw={200}
                  color={'text'}>
                  {` - ${
                    product?.fields.collection?.fields.diopterHelper?.fields
                      .text || staticContent.diopterSub
                  }`}
                </NoozText>
                <NoozIcon
                  iconKey={'InfoCircle'}
                  size={14}
                  strokeWidth={1}
                />
              </Flex>
            </Tooltip>
          ) : (
            <NoozText
              span
              size={14}
              fw={400}
              color={'text'}>
              {` - ${
                product?.fields.collection?.fields.diopterHelper?.fields.text ||
                staticContent.diopterSub
              }`}
            </NoozText>
          )}
        </NoozText>
        <Group>
          {
            /*active.shopifyVariants*/
            values.map((v, k) => {
              const activeDiopter = active?.shopifyInfo.selectedOptions.find(
                (s: any) => s.name === 'Diopter',
              )?.value;
              let stockObj =
                productUpdated?.variants
                  ?.filter((el: any) =>
                    (active?.shopifyVariants || [])
                      .map((v: any) => v.id)
                      ?.includes(el.id),
                  )
                  .find(
                    (sv: any) =>
                      sv.selectedOptions.find((s: any) => s.name === 'Diopter')
                        ?.value === v,
                  ) ||
                active?.shopifyVariants?.find(
                  (sv: any) =>
                    sv.selectedOptions.find((s: any) => s.name === 'Diopter')
                      ?.value === v,
                );
              let current = active?.shopifyVariants?.find(
                (sv: any) =>
                  sv.selectedOptions.find((s: any) => s.name === 'Diopter')
                    ?.value === v,
              );
              const isDisabled =
                !stockObj?.availableForSale &&
                stockObj?.inventoryPolicy != 'CONTINUE' &&
                !active?.fields.preOrder;

              return (
                <NoozButton
                  variant={
                    current && current.id === active?.shopifyInfo.id
                      ? 'filled'
                      : 'outline'
                  }
                  color={'black'}
                  disabled={isDisabled}
                  className={clsx(classes.chip, {
                    [classes.active]:
                      current && current.id === active?.shopifyInfo.id,
                    [classes.disabled]: isDisabled,
                  })}
                  onClick={() => {
                    handleDiopter(v);
                  }}
                  key={k}>
                  <NoozText
                    weight={400}
                    size={12}>
                    + {v.replace('+', '')}
                  </NoozText>
                </NoozButton>
              );
            })
          }
        </Group>

        <ProductEyeTest />
      </div>
    </Box>
  );
};

const ProductDiopterPicker = ({ styles }: UIDiopterPickerProps) => {
  const router = useRouter(); // @ts-ignore
  const { shopifyProduct } = usePageContext();
  const hasDiopterOption = useMemo(
    () =>
      shopifyProduct?.options.find(
        ({ name }: { name: string }) => name === 'Diopter',
      ),
    [shopifyProduct?.options],
  );
  const { variant, setVariant } =
    useProductContext() as unknown as ProductDiopterPickerProps;

  const handleDiopter = (diopter: string) => {
    const newQuery: Record<string, any> = {
      diopter: diopter,
    };
    Object.entries(router.query).forEach(([key, value]) => {
      if (!['locale', 'handles', 'diopter', LAST_VIEWED_EVENT].includes(key))
        newQuery[key] = value;
    });
    const newShopifyInfo = variant?.shopifyVariants?.find(
      (v) =>
        v.selectedOptions.find((s: any) => s.name === 'Diopter')?.value ===
        diopter,
    )!;
    const newVariant: IVariant = { ...variant, shopifyInfo: newShopifyInfo };
    setVariant(newVariant);
    // @ts-ignore
    if (typeof window !== 'undefined' && window.history?.pushState) {
      var newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?${new URLSearchParams(newQuery).toString()}`;
      window.history.replaceState({ path: newurl }, '', newurl);
    }
  };
  return hasDiopterOption?.values?.length > 1 ? (
    <UIDiopterPicker
      values={hasDiopterOption?.values || []}
      active={variant}
      handleDiopter={handleDiopter}
      styles={styles}
    />
  ) : null;
};

export default ProductDiopterPicker;
